// import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, LinearProgress, TextField, Typography } from '@mui/material';
import { useSpeedGame } from '../context/SpeedGameContext';
import CircularProgressWithText from '../components/CircularProgressWithText';
import { ChangeEvent, useEffect, useState } from 'react';
import { Check, Clear, Create, Refresh, SkipNext } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import { useTranslation } from 'react-i18next';

function GamePlayPage() {
    const { t } = useTranslation();
    const { speedGamePrompt, questions, isLoading, error, reset, speedGameMode } = useSpeedGame();
    const [index, setIndex] = useState(0);
    const [answers, setAnswers] = useState<boolean[]>([]);

    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);

    // const [failedToRender, setFailedToRender] = useState(false);
    const [isCurrentAnswerVisible, setIsCurrentAnswerVisible] = useState(false);
    const [isLoadingCurrentImage, setIsLoadingCurrentImage] = useState(false);

    const [playerAnswer, setPlayerAnswer] = useState('');
    const [playerAnswerIncorrect, setPlayerAnswerIncorrect] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPlayerAnswer(value);
    };

    const handleSkip = () => {
        setAnswers((prevAnswers) => [...prevAnswers, false]);
        setIndex(index + 1);
        setIsLoadingCurrentImage(true);
        // setFailedToRender(false);
        if (speedGameMode === 'group') {
            setIsCurrentAnswerVisible(false);
        } else if (speedGameMode === 'player') {
            setPlayerAnswer('');
            setPlayerAnswerIncorrect(false);
        }
    };

    const handleCorrect = () => {
        setIndex(index + 1);
        setAnswers((prevAnswers) => [...prevAnswers, true]);
        setIsLoadingCurrentImage(true);
        // setFailedToRender(false);
        if (speedGameMode === 'group') {
            setIsCurrentAnswerVisible(false);
        } else if (speedGameMode === 'player') {
            setPlayerAnswer('');
            setPlayerAnswerIncorrect(false);
        }
    };

    useEffect(() => {
        if (speedGameMode === 'admin') {
            setIsCurrentAnswerVisible(true);
        }
    }, [speedGameMode])

    useEffect(() => {
        if (playerAnswer === '') {
            setPlayerAnswerIncorrect(false);
            return;
        }
        if (playerAnswer.toLowerCase() === questions[index].value.toLocaleLowerCase()) {
            // handleCorrect();
            setIndex(index + 1);
            setAnswers((prevAnswers) => [...prevAnswers, true]);
            setIsLoadingCurrentImage(true);
            setPlayerAnswer('');
            setPlayerAnswerIncorrect(false);
            return;
        }

        setPlayerAnswerIncorrect(true);
    }, [playerAnswer, index, questions])

    // set end time
    useEffect(() => {
        if (index === questions.length) {
            setEndTime(new Date());
        }
    }, [index, questions.length]);

    // set start time
    useEffect(() => {
        if (!isLoading && index === 0) {
            setStartTime(new Date());
        }
    }, [isLoading, index]);


    if (error) {
        return (
            <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%'>
                <Box maxWidth="600px" width="100%" display='flex' flexDirection='column' textAlign='center' justifyContent="center" height="50vh" p={4}>
                    <Typography variant="h6">
                        {error}
                    </Typography>
                    <Box mt={5}>
                        <Button
                            component={Link}
                            to={ROUTES.speedgame.path}
                            variant="outlined"
                            color="error"
                            sx={{ borderRadius: 5, mb: 5 }}
                            startIcon={<Refresh />}
                            onClick={() => {
                                setIndex(0);
                                reset();
                            }}
                        >
                            {t("TryAgain")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    // loading questions
    if (isLoading) {
        return (
            <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%'>
                <Box maxWidth="600px" display='flex' flexDirection='column' textAlign='center' justifyContent="center" height="50vh">
                    <CircularProgressWithText />
                </Box>
            </Box>
        )
    }

    if (speedGamePrompt === '') {
        return (
            <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' width='100%'>
                <Box maxWidth="600px" width="100%" display='flex' flexDirection='column' textAlign='center' justifyContent="center" height="50vh" p={4}>
                    <Typography>{t("GameCreatePage_Title")}</Typography>
                    <Box mt={5}>
                        <Button
                            component={Link}
                            to={ROUTES.speedgame.path}
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: 5, mb: 5 }}
                            startIcon={<Create />}
                            onClick={() => {
                                setIndex(0);
                                reset();
                            }}
                        >
                            {t("GameCreatePage_Create")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    // game results
    if (index > 0 && index === questions.length && endTime !== null && startTime !== null) {
        const score = answers.filter((answer) => answer).length;
        const totalTimeInMilliseconds = endTime.getTime() - startTime.getTime();
        const totalTimeInSeconds = Math.floor(totalTimeInMilliseconds / 1000);
        const milliseconds = totalTimeInMilliseconds % 1000;

        return (
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-start'
                alignItems='center'
                width='100%'
            >
                <Box
                    maxWidth="600px"
                    width="100%"
                    display='flex'
                    flexDirection='column'
                    textAlign='center'
                    p={4}
                >
                    <Typography variant="h4" color="primary" mt={1}>
                        {speedGamePrompt}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <Typography variant="h6" color="primary" pr={2}>
                            {t("GamePlayPage_Score")}:
                        </Typography>
                        <Typography variant="h6" color="primary">
                            {score} / {answers.length}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt={1}>
                        <Typography variant="h6" color="primary" pr={2}>
                            {t("GamePlayPage_Time")}:
                        </Typography>
                        <Typography variant="h6" color="primary">
                            {totalTimeInSeconds}s {milliseconds}ms
                        </Typography>
                    </Box>
                </Box>
                {/* correct answers */}
                <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center" alignItems="center" m={5}>
                    {questions.map((question, index) => {
                        return (
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" key={`question_result_${index}`} m={2}>
                                <Box width="200px" height="200px">
                                    <img src={question.imageURI} alt={'img'} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20%' }} />
                                </Box>
                                <Typography variant="body2" mt={1} color="primary.dark">
                                    {question.value}
                                </Typography>
                                <Box mt={1}>
                                    {answers[index] ? <Check color="success" /> : <Clear color="error" />}
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Box mt={2}>
                    <Button
                        component={Link}
                        to={ROUTES.speedgame.path}
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: 5, mb: 5 }}
                        startIcon={<Refresh />}
                        onClick={() => {
                            setIndex(0);
                            reset();
                        }}
                    >
                        {t("GamePlayPage_StartNewGame")}
                    </Button>
                </Box>
            </Box>
        )
    }

    // shouldn't come here (unless for date time states)
    if (index === questions.length) {
        return <CircularProgressWithText />
    }

    const progress = ((index + 1) / questions.length) * 100;

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'
        >
            <Box
                maxWidth="600px"
                width="100%"
                display='flex'
                flexDirection='column'
                textAlign='center'
                p={4}
            >
                {/* Header */}
                <Box m={3} sx={{ top: 20 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Box flexGrow={1} paddingTop={2}>
                        <Typography variant="h6">
                            {`${t("GamePlayPage_Question")} ${index + 1}`}
                        </Typography>
                    </Box>
                </Box>

                {/* Question Image */}
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <Box>
                        {isLoadingCurrentImage && <CircularProgress />} {/* This is a loading spinner */}
                        <img
                            src={questions[index].imageURI}
                            alt={questions[index].value}
                            style={{ display: isLoadingCurrentImage ? 'none' : 'block', maxHeight: "70vh", maxWidth: "100%", borderRadius: 30 }}
                            onLoad={() => setIsLoadingCurrentImage(false)}
                            onError={() => setIsLoadingCurrentImage(false)}
                        />
                    </Box>
                </Box>

                {/* Player  */}
                {speedGameMode === "player"
                    ? <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" m={2}>
                        <TextField label={t('GamePlayPage_MyAnswer')} value={playerAnswer} onChange={handleInputChange} variant="outlined" fullWidth />
                        <Box ml={3}>
                            {playerAnswer !== "" && playerAnswerIncorrect ? <Clear color="error" /> : null}
                            {playerAnswer !== "" && !playerAnswerIncorrect ? <Check color="success" /> : null}
                        </Box>
                    </Box>
                    : null
                }

                {/* Correct Answer */}
                <Box sx={{ display: isLoadingCurrentImage || speedGameMode === 'player' ? 'none' : undefined }}>
                    {
                        isCurrentAnswerVisible
                            ? <Box mt={1}>
                                <Typography variant="caption" color="primary">
                                    ( {questions[index].imageQuery} )
                                </Typography>
                                <Typography variant="h4" color="primary" mt={2}>
                                    {questions[index].value}
                                </Typography>
                            </Box>
                            : <Box m={1}> <Button variant='outlined' sx={{ borderRadius: 5 }} onClick={() => setIsCurrentAnswerVisible(true)}> <Typography variant='subtitle1' color="primary"> {t("GamePlayPage_CheckAnswer")} </Typography> </Button> </Box>
                    }
                </Box>

                {/* Footer */}
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" m={2} mt={5} sx={{ display: isLoadingCurrentImage ? 'none' : 'flex' }}>
                    <Button variant="contained" color="warning" sx={{ borderRadius: 5 }} startIcon={<SkipNext />} onClick={handleSkip}>
                        {t("GamePlayPage_Skip")}
                    </Button>
                    {speedGameMode !== "player"
                        ? <Button variant="contained" color="success" sx={{ borderRadius: 5 }} startIcon={<Check />} onClick={handleCorrect}>
                            {t("GamePlayPage_Correct")}
                        </Button>
                        : null
                    }
                    {speedGameMode === "player" && playerAnswer !== "" && !playerAnswerIncorrect
                        ? <Button variant="contained" color="success" sx={{ borderRadius: 5 }} startIcon={<Check />} onClick={handleCorrect}>
                            {t("GamePlayPage_Next")}
                        </Button>
                        : null
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default GamePlayPage;
