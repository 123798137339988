import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import TopBar from '../navigations/TopBar';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos } from '@mui/icons-material';
import AnimatedLogo from '../components/AnimatedLogo';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    margin: 10,
    flexGrow: 1,
    minHeight: '80vh',
    maxWidth: '600px',
});

function Main() {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <TopBar navigations={[<DrawerNavigationBar />]} />
            <StyledContainer>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding={3} paddingTop={3} maxWidth="700px">
                    <Typography maxWidth="500px" variant="h5" color="primary" p={3}>
                        "{t("MainPage_Title")}"
                    </Typography>
                    <Typography variant="body1" color="primary" p={2} mb={2}>
                        {t("MainPage_Subtitle")}
                    </Typography>
                    <Link
                        to={`${ROUTES.speedgame.path}`}
                        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                    >
                        <Button variant="outlined" sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: 'linear-gradient(to bottom right, #63CFB160 0%, #5D9EB1F0 60%, #0569D0F0 100%)', color: 'white', minWidth: '200px' }}>
                            {t("MainPage_Instruction")}
                            <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                        </Button>
                    </Link>
                </Box>
                <Box sx={{ position: 'relative', bottom: 0, left: 0, right: 0, padding: 5 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Box margin={6}>
                        <AnimatedLogo clockwise={true} type="rotate" size="150px" />
                    </Box>
                    <Typography variant="caption" sx={{ fontSize: '8px' }}>
                        {t("MainPage_Copyright")}
                    </Typography>
                    <Box>
                        <Button component={Link} to={ROUTES.privacy.path} color="primary" sx={{ fontSize: '8px' }}>
                            {t("MainPage_PrivacyPolicy")}
                        </Button>
                        <Button component={Link} to={ROUTES.terms.path} color="primary" sx={{ fontSize: '8px' }}>
                            {t("MainPage_Terms")}
                        </Button>
                    </Box>
                </Box>
            </StyledContainer>
        </Box>
    );
}

export default Main;
