import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateSpeedGameSearchResults } from '../api/api';

function GameSearchTestPage() {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [imageURIs, setImageURIs] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleCopyClick = async (uri: string) => {
        try {
            await navigator.clipboard.writeText(uri);
            console.log('Text copied to clipboard:', uri);
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
        }
    };

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await generateSpeedGameSearchResults(searchQuery);
            console.log('response', response);
            setImageURIs(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching image URIs:', error);
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mt={5}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
            <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
                <TextField
                    label={t('Search')}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    variant="outlined"
                />
                <Button variant="outlined" onClick={handleSearchSubmit} sx={{ ml: 3 }}>
                    {t('Search')}
                </Button>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={5}>
                <Typography variant="h6" color="primary">{t('Search Results')}</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" maxWidth={800}>
                    {imageURIs.map((uri, index) => (
                        <Box display="flex" flexDirection="column" key={index} m={2}>
                            <img src={uri} alt={`search_result_${index}`} style={{ width: '200px', borderRadius: 30 }} />
                            <Button onClick={() => handleCopyClick(uri)} style={{ textTransform: 'lowercase' }}>
                                <Typography variant='caption' mt={1}> {uri.slice(0, 30)}... </Typography>
                            </Button>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default GameSearchTestPage;
