import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import ROUTES from '../utils/Routes';
import { Link } from 'react-router-dom';
import { logEvent } from '../utils/logging';
import { useTranslation } from 'react-i18next';

interface TopBarProps {
    navigations: React.ReactElement[] | null;
}

const TopBar: React.FC<TopBarProps> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                top: 0,
                width: '100%',
                textAlign: 'left',
            }}
        >
            <Box display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
            >
                <Box sx={{ textDecoration: 'None' }}>
                    <Link
                        to={ROUTES.main.path}
                        onClick={() => logEvent({
                            category: "Naviation",
                            action: "Click Home",
                        })}
                        style={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" m={2}>
                            <img
                                src="/logo48.png" // Replace with the correct URL or relative path to the logo192.png image
                                alt="Logo" // Provide an appropriate alt text for the logo image
                                style={{ height: '32px', width: '32px', marginRight: '8px', marginTop: '-7px', opacity: 0.7 }} // Add any necessary styling to the image
                            />
                            <Typography variant="h6" color={theme.palette.primary.main}>
                                {t('Nav_Title')}
                            </Typography>
                        </Box>
                    </Link>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                    {props.navigations?.map((nav) => <Box key={nav.key}>{nav}</Box>)}
                </Box>
            </Box>
            <Divider />
        </Box>
    );
};

export default TopBar;
