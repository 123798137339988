import React, { ReactNode, useState, useMemo, useCallback } from 'react';
import AuthContext, { AuthContextData } from '../context/AuthContext';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, User, signOut, FacebookAuthProvider, createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user] = useState<User | null>(null);
    const [isGuest, setIsGuest] = useState(false);

    const isAuthenticated = () => {
        return user !== null;
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        setIsGuest(false);
    };

    const signInWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        await signInWithPopup(auth, provider);
        setIsGuest(false);
    };

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseSignInWithEmailAndPassword(auth, email, password);
        setIsGuest(false);
    };

    const signUpWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signUpWithFacebook = async () => {
        const provider = new FacebookAuthProvider();
        await signInWithPopup(auth, provider);
    };

    const signUpWithEmailAndPassword = async (email: string, password: string) => {
        await firebaseCreateUserWithEmailAndPassword(auth, email, password);
    };

    const logout = async () => {
        await signOut(auth);
    };

    // Define the memoized functions using useCallback
    const isAuthenticatedMemoized = useCallback(isAuthenticated, [user]);
    const signInWithGoogleMemoized = useCallback(signInWithGoogle, []);
    const signInWithFacebookMemoized = useCallback(signInWithFacebook, []);
    const signInWithEmailAndPasswordMemoized = useCallback(signInWithEmailAndPassword, []);
    const signUpWithGoogleMemoized = useCallback(signUpWithGoogle, []);
    const signUpWithFacebookMemoized = useCallback(signUpWithFacebook, []);
    const signUpWithEmailAndPasswordMemoized = useCallback(signUpWithEmailAndPassword, []);
    const logoutMemoized = useCallback(logout, []);

    // Use useMemo to memoize the value object
    const value: AuthContextData = useMemo(
        () => ({
            user,
            isGuest,
            setIsGuest,
            isAuthenticated: isAuthenticatedMemoized,
            signInWithGoogle: signInWithGoogleMemoized,
            signInWithFacebook: signInWithFacebookMemoized,
            signInWithEmailAndPassword: signInWithEmailAndPasswordMemoized,
            signUpWithGoogle: signUpWithGoogleMemoized,
            signUpWithFacebook: signUpWithFacebookMemoized,
            signUpWithEmailAndPassword: signUpWithEmailAndPasswordMemoized,
            logout: logoutMemoized,
        }),
        [
            user,
            isGuest,
            setIsGuest,
            isAuthenticatedMemoized,
            signInWithGoogleMemoized,
            signInWithFacebookMemoized,
            signInWithEmailAndPasswordMemoized,
            signUpWithGoogleMemoized,
            signUpWithFacebookMemoized,
            signUpWithEmailAndPasswordMemoized,
            logoutMemoized,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
