import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 30,
    flexGrow: 1,
});

function TermsAndConditionsPage() {
    return (
        <>
            <StyledContainer>
                <Typography p={2} pl={0} variant="h6">
                    <b>Ipsum AI Terms of Service</b>
                </Typography>
                <Typography pb={2}>
                    <b>Effective Date: May 1, 2023</b>
                </Typography>
                <Typography variant="body1">
                    Welcome to Ipsum AI. Please read these Terms of Service ("Terms") carefully as they contain important information about your legal rights, remedies, and obligations. By accessing or using the Ipsum AI Service, you agree to comply with and be bound by these Terms.
                </Typography>
                <Typography p={2} pl={0} variant="h5">1. Introduction</Typography>
                <Typography variant="body1">
                    These Terms of Service constitute a legally binding agreement ("Agreement") between you and Ipsum AI governing your access to and use of the Ipsum AI website, including any subdomains thereof, and any other websites through which Ipsum AI makes its services available (collectively, "Site"), our mobile, tablet and other smart device applications, and application program interfaces (collectively, "Application") and all associated services (collectively, "Ipsum AI Services").
                </Typography>

                <Typography p={2} pl={0} variant="h5">2. Eligibility</Typography>
                <Typography variant="body1">
                    The Ipsum AI Services are intended solely for persons who are 13 or older. Any access to or use of the Ipsum AI Services by anyone under 13 is expressly prohibited. By accessing or using the Ipsum AI Services, you represent and warrant that you are 13 or older.
                </Typography>

                <Typography p={2} pl={0} variant="h5">3. Modification</Typography>
                <Typography variant="body1">
                    Ipsum AI reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the Ipsum AI Site and update the “Last Updated” date at the top of these Terms. Your continued access to or use of the Ipsum AI Services will constitute acceptance of the revised Terms.
                </Typography>

                <Typography p={2} pl={0} variant="h5">4. User Conduct</Typography>
                <Typography variant="body1">
                    You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Ipsum AI Services. In connection with your use of the Ipsum AI Services, you may not and you agree that you will not:
                </Typography>

                <Typography variant="body2">
                    - Violate any local, state, provincial, national, or other law or regulation, or any order of a court.<br />
                    - Use the Ipsum AI Services for any commercial or other purposes that are not expressly permitted by these Terms.<br />
                    - Use the Ipsum AI Services to transmit, distribute, post or submit any information concerning any other person or entity without their permission.<br />
                </Typography>

                <Typography p={2} pl={0} variant="h5">5. Privacy</Typography>
                <Typography variant="body1">
                    Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users.
                </Typography>

                <Typography p={2} pl={0} variant="h5">6. Ownership</Typography>
                <Typography variant="body1">
                    The Ipsum AI Services are protected by copyright, trademark, and other laws of the United States and foreign countries. You acknowledge and agree that the Ipsum AI Services, including all associated intellectual property rights, are the exclusive property of Ipsum AI.
                </Typography>

                <Typography p={2} pl={0} variant="h5">7. Disclaimers</Typography>
                <Typography variant="body1">
                    The Ipsum AI Services are provided "as is", without warranty of any kind, either express or implied. Ipsum AI makes no warranty that the Ipsum AI Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis.
                </Typography>

                <Typography p={2} pl={0} variant="h5">8. Indemnity</Typography>
                <Typography variant="body1">
                    You agree to defend, indemnify, and hold Ipsum AI, its officers, directors, employees, and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Ipsum AI Services.
                </Typography>

                <Typography p={2} pl={0} variant="h5">9. Termination and Ipsum AI Account Cancellation</Typography>
                <Typography variant="body1">
                    Ipsum AI may, in its sole discretion and without liability to you, with or without cause, with or without prior notice and at any time, terminate these Terms or your access to the Ipsum AI Services.
                </Typography>

                <Typography p={2} pl={0} variant="h5">10. Governing Law and Jurisdiction</Typography>
                <Typography variant="body1">
                    These Terms will be interpreted in accordance with the laws of the State of California, without regard to its conflict-of-law provisions.
                </Typography>

                <Typography p={2} pl={0} variant="h5">11. General</Typography>
                <Typography variant="body1">
                    The failure of Ipsum AI to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Ipsum AI. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. If for any reason an arbitrator or a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.
                </Typography>

                <Typography p={2} pl={0} variant="h5">12. Contacting Ipsum AI</Typography>
                <Typography variant="body1">
                    If you have any questions about these Terms, please contact Ipsum AI at support@ipsum-ai.com.
                </Typography>

                <Typography p={2} pl={0} variant="h5">13. Dispute Resolution</Typography>
                <Typography variant="body1">
                    You and Ipsum AI agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof, or to the use of the Ipsum AI Services (collectively, "Disputes") will be settled by binding arbitration, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.
                </Typography>

                <Typography p={2} pl={0} variant="h5">14. Feedback</Typography>
                <Typography variant="body1">
                    We welcome and encourage you to provide feedback, comments and suggestions for improvements to the Ipsum AI Services ("Feedback"). You may submit Feedback by emailing us at support@ipsum-ai.com. You acknowledge and agree that all Feedback will be the sole and exclusive property of Ipsum AI and you hereby irrevocably assign to Ipsum AI and agree to irrevocably assign to Ipsum AI all of your right, title, and interest in and to all Feedback, including without limitation all worldwide patent, copyright, trade secret, moral and other proprietary or intellectual property rights therein.
                </Typography>

                <Typography p={2} pl={0} variant="h5">15. Safety</Typography>
                <Typography variant="body1">
                    We do our best to keep Ipsum AI safe, but we cannot guarantee it. We need your help to keep Ipsum AI safe, which includes the following commitments by you:
                </Typography>
                <br />
                <Typography variant="body2">
                    - You will not post unauthorized commercial communications (such as spam) on Ipsum AI.<br />
                    - You will not collect users' content or information, or otherwise access Ipsum AI, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission.<br />
                    - You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on Ipsum AI.<br />
                    - You will not upload viruses or other malicious code.<br />
                    - You will not bully, intimidate, or harass any user.<br />
                    - You will not post content that: is hate speech, threatening, or pornographic; incites violence; or contains nudity or graphic or gratuitous violence.<br />
                </Typography>

                <Typography p={2} pl={0} variant="h5">16. Payments</Typography>
                <Typography variant="body1">
                    If you purchase any of our paid services, you agree to pay us the applicable fees and taxes. Failure to pay these fees may result in the termination of your subscription. Also:
                </Typography>
                <Typography variant="body2">
                    - Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).<br />
                    - You authorize us to store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your service (e.g. subscriptions) and to facilitate easy payment for new services.<br />
                    - You must pay us for applicable fees and taxes unless you cancel the service, in which case you agree to still pay these fees through the end of the applicable subscription period.<br />
                    - These Terms constitute the entire and exclusive understanding and agreement between Ipsum AI and you regarding the Ipsum AI Services, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Ipsum AI and you regarding the Ipsum AI Services.<br />
                </Typography>

                <Typography p={2} pl={0} variant="h5">17. Intended Use of Ipsum AI Services / Products</Typography>
                <Typography variant="body1">
                    Please read this section carefully as it outlines how our services and products, which include our website, are intended to be used.
                </Typography>

                <Typography variant="body2">
                    Ipsum AI does not make any claims or representations in relation to the emotional, health, or commercial benefits of using our products, and the information provided on the website is no substitute for professional medical or psychiatric advice where applicable. If you are concerned about health or mental well-being issues, you are advised to consult your doctor. In particular:
                    <br />
                    - Our Products are provided for information and entertainment purposes only and not for medical or psychological assessment purposes. They are for your own personal use (and not, for example, to screen or assess others) and for no other purpose.<br />
                    - Our Products do not provide medical advice.<br />
                    - Our Products should not be regarded as or relied upon as being a comprehensive or accurate opinion or assessment concerning your psychological well-being and personality. We just do not know enough about you to guarantee that. Where you have any concerns arising out of your use of our Products, you should seek appropriate professional advice.<br />
                    - Any decisions that you make once you have used or read the content of our Products are for you alone, and we will not be liable for the consequences of any such decisions.<br />
                    - Our Products are for personal use only and are not intended for use in any business, educational, employment, or recruitment context.<br />
                    - While we provide translations of our Products in languages other than English, the accuracy of such translations is not guaranteed (for example, many translations are contributed by volunteers), and use is at your own risk.<br />
                    - Ipsum AI services and products are designed to inspire personal growth and foster deeper connections with others. They are not intended to replace professional advice or to be used in a professional context. Always consult with a professional when dealing with mental health and well-being issues.<br />
                </Typography>
            </StyledContainer >
        </>
    );
}

export default TermsAndConditionsPage;
