import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 30,
    flexGrow: 1,
});

function ProfilePage() {
    return (
        <StyledContainer>
            <Typography p={2} pl={0} variant="h6">
                <b>Ipsum AI Privacy Policy</b>
            </Typography>
            <Typography pb={2}>
                <b>Effective Date: May 1, 2023</b>
            </Typography>

            <Typography variant="body1">
                At Ipsum AI, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines the types of information we collect, how we use and protect that information, and your rights regarding your personal data. By using our services, you agree to the collection and use of your information in accordance with this Privacy Policy.
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 1. Information We Collect</Typography>
            <Typography variant="body1">
                We may collect the following types of information:<br />
                - Personal Information: Including your name, email address, date of birth, gender, and any other information you provide when creating an account or participating in our services.<br />
                - Usage Data: Information about your interactions with our platform, such as the time, date, and duration of your sessions, the questions you answer, and the results you obtain.<br />
                - Device Information: Information about your device, such as your IP address, browser type, operating system, and device identifiers.<br />
                - Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your use of our platform, remember your preferences, and optimize your experience.<br />
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 2. How We Use Your Information</Typography>

            <Typography variant="body1">
                We use the information we collect for the following purposes:<br />
                - To provide and improve our services.<br />
                - To personalize your experience on our platform and provide tailored content and recommendations.<br />
                - To analyze usage trends and optimize the performance of our platform.<br />
                - To communicate with you regarding updates, promotions, and important information.<br />
                - To comply with legal obligations and protect the rights and interests of our users and our company.<br />

            </Typography>

            <Typography p={2} pl={0} variant="h5"> 3. Sharing Your Information</Typography>

            <Typography variant="body1">
                We may share your information with third-party service providers for the purposes of providing and improving our services, such as data storage, data analysis, and email delivery. We only share your information with trusted partners who have agreed to keep your information confidential and secure.<br />
                We may also disclose your information if required by law or in the event of a merger, acquisition, or sale of our company assets.<br />
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 4. Protecting Your Information</Typography>

            <Typography variant="body1">
                We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission or storage is 100% secure, and we cannot guarantee absolute security.
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 5. Your Rights</Typography>

            <Typography variant="body1">
                You have the right to access, edit, and delete your personal information held by Ipsum AI. You can exercise these rights by logging into your account or contacting us at support@ipsum-ai.com. If you wish to withdraw your consent for the processing of your personal information, you may do so by closing your account.
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 6. Children's Privacy</Typography>

            <Typography variant="body1">
                Our platform is not intended for users under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe we have collected information from a child, please contact us at support@ipsum-ai.com, and we will take appropriate action to remove such information.
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 7. International Data Transfers</Typography>

            <Typography variant="body1">
                Your personal information may be transferred to and processed in countries other than your country of residence. By using our platform, you consent to the transfer of your information to these countries, which may have different data protection laws.
            </Typography>
            <Typography p={2} pl={0} variant="h5"> 8. Changes to This Privacy Policy</Typography>

            <Typography variant="body1">
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated Privacy Policy on our website and informing you via email. Your continued use of our platform after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.
            </Typography>

            <Typography p={2} pl={0} variant="h5"> 9. Contact Us</Typography>

            <Typography variant="body1">
                If you have any questions or concerns about this Privacy Policy, please contact us at support@ipsum-ai.com
            </Typography>
        </StyledContainer>

    );
}

export default ProfilePage;
