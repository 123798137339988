import { useState, useEffect } from 'react';
import { Box } from '@mui/system';

const AnimatedLogo: React.FC<{ clockwise: boolean, type: 'rotate' | 'flash' | 'none', size: string }> = ({ clockwise, type, size }) => {
    const [rotation, setRotation] = useState(0);
    const [opacity, setOpacity] = useState(0.8);

    useEffect(() => {
        if (type === 'flash') {
            const interval = setInterval(() => {
                setRotation((prevRotation) => (clockwise ? prevRotation + 45 : prevRotation - 45) % (360 * 3));
                setOpacity((prevOpacity) => (prevOpacity === 0.8 ? 0.3 : 0.8));
            }, 1000) // Adjust the interval for a smoother animation
            return () => {
                clearInterval(interval);
            };
        }
    }, [clockwise, type]);

    useEffect(() => {
        if (type === 'rotate') {
            const interval = setInterval(() => {
                setRotation((prevRotation) => (clockwise ? prevRotation + 90 : prevRotation - 90) % (360 * 3));
            }, 1500)
            return () => {
                clearInterval(interval);
            };
        }
    }, [clockwise, type]);

    let boxStyles = undefined;

    if (type === 'flash') {
        boxStyles = {
            opacity,
            transform: `scale(1) rotate(${rotation}deg)`,
            transition: 'opacity 1s ease, transform 1s ease',
            animation: 'rotate 1s linear infinite',
        };
    } else if (type === 'rotate') {
        boxStyles = {
            opacity,
            transform: `scale(1) rotate(${rotation}deg)`,
            transition: 'opacity 3s ease, transform 1s ease',
            animation: 'rotate 5s linear infinite',
        };
    }

    return (
        <Box style={boxStyles}>
            <img
                src="/logo200.png"
                alt="Logo"
                height={size}
                width={size}
            />
        </Box>
    );
};

export default AnimatedLogo;