import { createContext, useContext } from 'react';
import { SpeedGameQuestions } from '../utils/interfaces';

export interface SpeedGameContextData {
    speedGamePrompt: string;
    setSpeedGamePrompt: React.Dispatch<React.SetStateAction<string>>;
    speedGameMode: string;
    setSpeedGameMode: React.Dispatch<React.SetStateAction<string>>;
    numResults: number;
    setNumResults: React.Dispatch<React.SetStateAction<number>>;
    questions: SpeedGameQuestions;
    isLoading: boolean;
    error: string;
    reset: () => void;
}

const SpeedGameContext = createContext<SpeedGameContextData | undefined>(undefined);

export const useSpeedGame = (): SpeedGameContextData => {
    const context = useContext(SpeedGameContext);
    if (!context) {
        throw new Error('useSpeedGame must be used within a SpeedGameProvider');
    }
    return context;
};

export default SpeedGameContext;
