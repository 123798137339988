import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ROUTES from '../utils/Routes';
import { ArrowForwardIos } from '@mui/icons-material';
import TopBar from '../navigations/TopBar';
import DrawerNavigationBar from '../navigations/DrawerNavigationBar';
import AnimatedLogo from '../components/AnimatedLogo';
import { ChangeEvent, useState } from 'react';
import { useSpeedGame } from '../context/SpeedGameContext';
import { useTranslation } from 'react-i18next';


function GameCreatePage() {
    const { t } = useTranslation();
    const { setSpeedGamePrompt, reset, speedGameMode, setSpeedGameMode, numResults, setNumResults } = useSpeedGame();

    const [prompt, setPrompt] = useState('');
    const [, setPresetPrompt] = useState('');
    const [userPrompt, setUserPrompt] = useState('');

    const disabled = prompt === '' || prompt === null;

    const handlePresetPromptChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPresetPrompt(event.target.value);
        setPrompt(event.target.value);
    };

    const handleUserPromptChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserPrompt(event.target.value);
        setPrompt(event.target.value);
    };

    const handleCreateSpeedGame = () => {
        reset();
        setSpeedGamePrompt(prompt);
    }

    const handleGameModeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSpeedGameMode(value);
    };

    const handleNumResultModeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNumResults(Number(value));
    };

    const gameModes = [
        { label: 'Player', value: 'player' },
        { label: 'Group', value: 'group' },
        { label: 'Admin', value: 'admin' },
    ];

    const numResultModes = [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
    ];

    const SAMPLE_PROMPTS = t('GameCreatePage_SamplePrompts').split(',');

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'
        >
            <TopBar
                navigations={
                    [
                        <DrawerNavigationBar />
                    ]
                } />
            <Box
                maxWidth="600px"
                display='flex'
                flexDirection='column'
                textAlign='center'
                p={4}
            >
                <Box mt={2}>
                    <Typography variant="h4" color="primary" p={2}>
                        {t('GameCreatePage_Title')}
                    </Typography>
                    <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography variant="h6" color="primary" p={2}>
                            {t('GameCreatePage_SelectPrompt')}
                        </Typography>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="left" width="100%">
                            {SAMPLE_PROMPTS.map((sample_prompt) => (
                                <Button
                                    key={sample_prompt}
                                    variant={sample_prompt === prompt ? 'contained' : 'outlined'}
                                    onClick={() => handlePresetPromptChange({ target: { value: sample_prompt } } as ChangeEvent<HTMLInputElement>)}
                                    sx={{ m: 1, borderRadius: 4, textTransform: 'lowercase' }}
                                >
                                    {sample_prompt}
                                </Button>
                            ))}
                        </Box>
                        <Typography variant="h6" color="primary" p={2}>
                            {t('GameCreatePage_WritePrompt')}
                        </Typography>
                        <TextField variant="outlined" placeholder={SAMPLE_PROMPTS[0]} value={userPrompt} onChange={handleUserPromptChange} fullWidth />
                    </Box>
                    <Box m={4} maxWidth="400px">
                        <Typography variant="h5" color="primary">
                            {prompt}
                        </Typography>
                    </Box>
                    <Box >
                        <Typography color="primary">Game Mode</Typography>
                        <RadioGroup value={speedGameMode} onChange={handleGameModeChange}>
                            <Box display="flex" flexDirection="row" justifyContent="space-around" m={2}>
                                {gameModes.map((mode) => (
                                    <FormControlLabel key={mode.value} value={mode.value} control={<Radio />} label={mode.label} />
                                ))}
                            </Box>
                        </RadioGroup>
                        <Typography color="primary">Number of Questions</Typography>
                        <RadioGroup value={numResults} onChange={handleNumResultModeChange}>
                            <Box display="flex" flexDirection="row" justifyContent="space-around" m={2}>
                                {numResultModes.map((mode) => (
                                    <FormControlLabel key={mode.value} value={mode.value} control={<Radio />} label={mode.label} />
                                ))}
                            </Box>
                        </RadioGroup>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
                        <Box p={2}>
                            <Button
                                disabled={disabled}
                                component={Link}
                                to={ROUTES.play.path}
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: 8, p: 2, border: 'hidden', backgroundImage: disabled ? undefined : 'linear-gradient(to bottom right, #63CFB160 0%, #2B9C32C0 100%)', color: 'white', minWidth: '200px' }}
                                onClick={() => handleCreateSpeedGame()}>
                                {t('GameCreatePage_Create')}
                                <ArrowForwardIos sx={{ fontSize: '.7rem', marginLeft: '.5rem' }} />
                            </Button>
                        </Box>
                        <Box m={3} sx={{ opacity: 0.9 }}>
                            <AnimatedLogo clockwise={true} type="rotate" size="120px" />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}

export default GameCreatePage;
