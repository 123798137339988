import React, { ReactNode, useEffect, useState } from 'react';
import SpeedGameContext, { SpeedGameContextData } from '../context/SpeedGameContext';
import { SpeedGameQuestions } from '../utils/interfaces';
import { generateSpeedGameQuestions } from '../api/api';

interface SpeedGameProviderProps {
    children: ReactNode;
}

const SpeedGameProvider: React.FC<SpeedGameProviderProps> = ({ children }) => {
    const [speedGamePrompt, setSpeedGamePrompt] = useState<string>('');
    const [questions, setQuestions] = useState<SpeedGameQuestions>([]);
    const [speedGameMode, setSpeedGameMode] = useState('player');
    const [numResults, setNumResults] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const generate = async () => {
            try {
                if (speedGamePrompt && speedGamePrompt !== '') {
                    setIsLoading(true);
                    // const response = await generateSpeedGameQuestionsMock(speedGamePrompt);
                    const response = await generateSpeedGameQuestions(speedGamePrompt, numResults);
                    setQuestions(response);
                    setIsLoading(false);
                }
            } catch (error: any) {
                setError(error.message);
                setIsLoading(false);
            }
        }
        generate();
    }, [speedGamePrompt, numResults]);

    const reset = () => {
        setSpeedGamePrompt('');
        setIsLoading(false);
        setError('');
        setQuestions([]);
    }

    // Use useMemo to memoize the value object
    const value: SpeedGameContextData = {
        speedGamePrompt,
        setSpeedGamePrompt,
        speedGameMode,
        setSpeedGameMode,
        numResults,
        setNumResults,
        questions,
        isLoading,
        error,
        reset,
    };

    return (
        <SpeedGameContext.Provider value={value}>{children}</SpeedGameContext.Provider>
    );
};

export default SpeedGameProvider;
