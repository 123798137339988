import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AnimatedLogo from './AnimatedLogo';
import { useTranslation } from 'react-i18next';

const CircularProgressWithText: React.FC = () => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(0);

    const wittyTexts = t('LoadingText').split(',')

    useEffect(() => {
        const changeText = () => {
            setIndex(Math.floor(Math.random() * wittyTexts.length));
        };
        const interval = setInterval(changeText, 3000);
        return () => clearInterval(interval);
    }, [wittyTexts]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" textAlign="center">
            <Box mt={3}>
                <AnimatedLogo clockwise={true} type={'flash'} size="130px" />
            </Box>
            <Typography variant="subtitle2" p={3} pb={5}>{wittyTexts[index]}...</Typography>
        </Box >
    );
};

export default CircularProgressWithText;
