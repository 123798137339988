import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "ai-personality-test.firebaseapp.com",
    projectId: "ai-personality-test",
    storageBucket: "ai-personality-test.appspot.com",
    messagingSenderId: "1026297714415",
    appId: "1:1026297714415:web:e7ab3425444c1cb43a6d1a",
    measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export { auth, db };
