import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from "./locales/en.json";
import translationKO from "./locales/ko.json";
import translationZH from "./locales/zh.json";


const resources = {
    en: {
        translation: translationEN
    },
    ko: {
        translation: translationKO
    },
    zh: {
        translation: translationZH
    }
};
i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        resources
    });

export default i18n;
