import { SpeedGameQuestions } from "../utils/interfaces";

export const generateSpeedGameQuestionsMock = async (query: string): Promise<SpeedGameQuestions> => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const MOCK_QUESTIONS: SpeedGameQuestions = [
        {
            value: 'John Mayer',
            imageURI: 'https://hips.hearstapps.com/hmg-prod/images/gettyimages-942065100-copy.jpg?resize=1200:*',
        },
        {
            value: 'John Legend',
            imageURI: 'https://hips.hearstapps.com/hmg-prod/images/gettyimages-1230039869.jpg',
        },
        {
            value: 'Jason Mraz',
            imageURI: 'https://yt3.googleusercontent.com/v_IjNp26-BbphbAJYfWzEGwJJu9V3bkVFx5rdh0awOijRC8T1qVujB5fpIrNE1Lw2sseaW6s=s900-c-k-c0x00ffffff-no-rj',
        },
    ];

    return MOCK_QUESTIONS;
};

export const generateSpeedGameQuestions = async (query: string, num_results: number): Promise<SpeedGameQuestions> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generateSpeedGameQuestions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query,
                num_results
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        console.log('generateSpeedGameQuestions', responseData.result);
        return responseData.result as SpeedGameQuestions;
    } catch (error: any) {
        console.error(error);
        if (error.message) {
            throw error;
        }
        throw new Error(`Something wrong happened while generating. Please try again.`);
    }
};

export const generateSpeedGameSearchResults = async (query: string): Promise<string[]> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/generateSpeedGameSearchResults`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query,
            }),
        });
        const responseData = await response.json();
        if (response.status !== 200) {
            throw responseData.error || new Error(`Request failed with status ${response.status}`);
        }
        console.log('generateSpeedGameSearchResults', responseData.result);
        return responseData.result as string[];
    } catch (error: any) {
        console.error(error);
        if (error.message) {
            throw error;
        }
        throw new Error(`Something wrong happened while generating. Please try again.`);
    }
};
