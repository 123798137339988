import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ROUTES from './utils/Routes';
import MainPage from './pages/MainPage';
import Box from '@mui/material/Box';
import GameCreatePage from './pages/GameCreatePage';
import { CssBaseline } from '@mui/material';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import AuthProvider from './provider/AuthProvider';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import GamePlayPage from './pages/GamePlayPage';
import SpeedGameProvider from './provider/SpeedGameProvider';
import GameSearchTestPage from './pages/GameSearchTestPage';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '');

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontFamily: "Comfortaa, Outfit, Robotto, sans-serif"
        },
        body: {
          margin: "0",
          fontFamily: "Comfortaa, Outfit, Robotto, sans-serif",
        }
      },
    },
  },
  typography: {
    fontFamily: [
      'Comfortaa',
      'Outfit',
      'Roboto',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#6D799C', // dusty blue
      light: '#8b95b1', // lighter dusty blue
      dark: '#496179', // dark dusty blue
      contrastText: '#fff',
    },
    secondary: {
      main: '#7BB8B1', // dusty teal
      light: '#587B77', // lighter dusty teal
      dark: '#396A75', // dark teal
      contrastText: '#fff',
    },
    error: {
      main: '#FF6781', // coral
      light: '#ffe4e4', // coral (lighter shade)
      dark: '#ffdfdf', // crimson
      contrastText: '#333',
    },
    warning: {
      main: '#fdecb7', // mustard
      light: '#fff9e6', // mustard (lighter shade)
      dark: '#fff6e5', // burnt sienna
      contrastText: '#333',
    },
    info: {
      main: '#9F978D', // gold grey
      light: '#f7f6ff', // light purple (lighter shade)
      dark: '#f2f1ff', // deep purple
      contrastText: '#333',
    },
    success: {
      main: '#8ED8BC', // mint
      light: '#D8EAE4', // mint (lighter shade)
      dark: '#e2fbeb', // teal
      contrastText: '#333',
    },
  },
});

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <SpeedGameProvider>
          <Router>
            <Box component="main">
              <Box
                display="flex"
                flexDirection="column"
              >
                <Routes>
                  {/* main */}
                  <Route path={ROUTES.main.path} element={<MainPage />} />
                  {/* game */}
                  <Route path={ROUTES.speedgame.path} element={<Outlet />}>
                    <Route index element={<GameCreatePage />} />
                    <Route path={ROUTES.play.path} element={<Outlet />} >
                      <Route index element={<GamePlayPage />} />
                    </Route>
                    <Route path={'*'} element={<div>{t("ERROR_PageNotFound")}</div>} />
                  </Route>
                  {/* search */}
                  <Route path={ROUTES.search.path} element={<GameSearchTestPage />} />
                  {/* privacy policy */}
                  <Route path={ROUTES.privacy.path} element={<PrivacyPolicyPage />} />
                  {/* terms and conditions */}
                  <Route path={ROUTES.terms.path} element={<TermsAndConditionsPage />} />
                  {/* 404 */}
                  <Route path={'*'} element={<div>{t("ERROR_PageNotFound")}</div>} />
                </Routes>
              </Box>
            </Box>
          </Router>
        </SpeedGameProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;