const ROUTES = {
    main: {
        path: '/',
    },
    speedgame: {
        path: '/speedgame',
    },
    play: {
        path: 'play',
    },
    result: {
        path: 'result',
    },
    search: {
        path: '/search',
    },
    privacy: {
        path: '/privacy',
    },
    terms: {
        path: '/terms',
    }
}

export default ROUTES;